export interface ISoprApiParams {
  apiUrl: string;
  contentType: string;
  dataToSopr: string;
  path: string;
}

export async function SoprAPI(params: ISoprApiParams): Promise<void> {
  const { apiUrl, dataToSopr, contentType, path } = params;

  if (window.fetch) {
    const response = await window.fetch(`${apiUrl}${path}/`, {
      method: 'POST',
      headers: { 'Content-Type': contentType },
      body: dataToSopr,
      keepalive: true,
      credentials: 'include',
    });

    if (response.status === 200 || response.status === 204) {
      return;
    }

    throw new Error();
  }

  const xhr = new XMLHttpRequest();

  return new Promise<void>((resolve, reject) => {
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200 || xhr.status === 204) {
          resolve();
        } else {
          reject();
        }
      }
    };

    xhr.withCredentials = true;
    xhr.open('POST', `${apiUrl}${path}/`);
    xhr.setRequestHeader('Content-Type', contentType);
    xhr.send(dataToSopr);
  });
}
