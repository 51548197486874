/**
 * Функция для переименования пришедших с frontend'a полей, в валидные
 * поля для SOPR и GA
 */
export function getRightSoprKey(key: string): string {
  const soprKeys: { [key: string]: string } = {
    category: 'ec',
    eventCategory: 'ec',
    action: 'ea',
    eventAction: 'ea',
    label: 'el',
    eventLabel: 'el',
    value: 'ev',
    eventValue: 'ev',
    hitType: 't',
    searchConditions: 'sc',
    searchResults: 'sr',
  };

  return soprKeys[key] ? soprKeys[key] : key;
}
