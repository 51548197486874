// tslint:disable:ordered-imports
import * as uuid from 'uuid';

import { IEventProperties, ISOPRDataCollection, IUserNewSopr, IPageNewSopr } from './../types';
import { Config } from './../models/config';
import * as objectAssign from 'object-assign';
import { IDataLayerEvent } from './../types/dataLayer';

export class SoprEvent {
  private data: IEventProperties;
  private sopr: ISOPRDataCollection;
  private custom: IEventProperties | undefined;
  private query: {};
  public constructor(
    // tslint:disable-next-line:no-any
    eventData: any,
    config: Config,
    customValues?: IEventProperties,
    withUtm?: boolean,
  ) {
    this.data = eventData;
    // eslint-disable-next-line import/namespace
    this.data['join_id'] = uuid.v4();
    this.custom = customValues;
    this.sopr = config.getSoprData('event', withUtm);
  }

  public getSoprParams(): string {
    const parts: string[] = [];

    /**
     * Для старых событий utm в корне не передаем
     */
    const { utm, ...sopr } = this.sopr;

    Object.keys(sopr).forEach(key => {
      parts.push(`${key}=${encodeURIComponent(String(sopr[key]))}`);
    });

    let reqData = this.data;

    if (this.custom) {
      reqData = objectAssign(this.custom, this.data);
    }

    Object.keys(reqData).forEach(key => {
      let value = reqData[key];

      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      parts.push(`${this.getRightSoprKey(key)}=${encodeURIComponent(value)}`);
    });

    parts.push(`referrer=${encodeURIComponent(document.referrer)}`);

    this.query = parts;

    return parts.join('&');
  }

  public getDataLayerParams(): IDataLayerEvent {
    const dataLayer: IDataLayerEvent = Object.keys(this.data).reduce((prev, current) => {
      // tslint:disable-next-line:no-any
      (prev as any)[this.replaceEventKeys(current)] = this.data[current];

      return prev;
    }, {});

    return dataLayer;
  }

  // rename keys to sopr
  private getRightSoprKey = (key: string): string => {
    // tslint:disable-next-line:no-any
    const soprKeys: any = {
      category: 'ec',
      eventCategory: 'ec',
      action: 'ea',
      eventAction: 'ea',
      label: 'el',
      eventLabel: 'el',
      value: 'ev',
      eventValue: 'ev',
      hitType: 't',
      searchConditions: 'sc',
      searchResults: 'sr',
    };

    return soprKeys[key] ? soprKeys[key] : key;
  };

  private replaceEventKeys = (eventName: string): string => {
    // tslint:disable-next-line:no-any
    const dict: any = {
      name: 'event',
      category: 'eventCategory',
      label: 'eventLabel',
      action: 'eventAction',
    };

    return dict[eventName] || eventName;
  };

  // tslint:disable-next-line:no-any
  private getUser = (eventObject: { [key: string]: any }, config?: Config) => {
    if (eventObject.user) {
      return eventObject.user;
    }

    if (config && config.user) {
      const { abGroup, userId } = config.user as IUserNewSopr;

      return { abGroup, userId, isAuthorized: Boolean(userId) };
    }

    return undefined;
  };

  // tslint:disable-next-line:no-any
  private getPage = (eventObject: { [key: string]: any }, config?: Config) => {
    if (eventObject.page) {
      return eventObject.page;
    }

    if (config && config.page) {
      const {
        listingType,
        sortType,
        pageNumber,
        block,
        breadCrumbs,
        offerType,
        offerID,
        queryString,
        pageType,
        siteType,
        offersQty,
      } = config.page as IPageNewSopr;

      return {
        listingType,
        sortType,
        pageNumber,
        block,
        breadCrumbs,
        offerType,
        offerID,
        queryString,
        pageType,
        siteType,
        offersQty,
      };
    }

    return undefined;
  };
}
