import { Logger } from './logger';
import { SoprAPI } from '../api_caller';
import { Config, IConfigParams } from '../models/config';
import { SoprEvent } from '../models/event';
import {
  IEventProperties,
  IPage,
  IPageNewSopr,
  IUser,
  IUserNewSopr,
  IPartner,
  IPartnerNewSopr,
  IProducts,
  IPageviewData,
} from '../types';
import { getSoprApi } from '../utils';
import { IPageviewEbc, IEventEbc } from '../types/ebc';

import { collect, sendTeaser } from '../tracking';
import { TTeaserUserEventData } from '../types/teaser';

export class Service {
  public config: Config;
  public logger: Logger;

  public constructor() {
    this.logger = new Logger();
  }

  // API
  public user(user: IUser | IUserNewSopr) {
    this.config.setUser(user);
  }

  public page(page: IPage | IPageNewSopr) {
    this.config.setPage(page);
  }

  public sc(sc: object) {
    this.config.setSc(sc);
  }

  public partner(partner: IPartner | IPartnerNewSopr) {
    this.config.setPartner(partner);
  }

  public products(products: IProducts[]) {
    this.config.setProducts(products);
  }

  public teaser(data: TTeaserUserEventData) {
    sendTeaser({ config: this.config, data });
  }

  public eventSite(data: IEventProperties) {
    const reqData = Object.assign({ event: 'oldevent' }, data);

    collect({
      // tslint:disable-next-line:no-any
      eventData: reqData as any,
      config: this.config,
      isNewpage: false,
    });
  }

  public pageviewSite(data?: IPageviewData) {
    this.pageview(data);
  }

  public event(data: IEventProperties) {
    this.send(new SoprEvent(data, this.config));
  }

  public pageview(data?: IPageviewData) {
    collect({
      // tslint:disable-next-line:no-any
      eventData: data as any,
      config: this.config,
      isNewpage: true,
    });
  }

  public eventEnrich(data?: IEventProperties) {
    collect({
      // tslint:disable-next-line:no-any
      eventData: data as any,
      config: this.config,
      isNewpage: false,
      withEnrich: true,
    });
  }

  public eventEbc(data: IEventEbc) {
    const rawEvent = Object.assign({ event: 'oldevent' }, data);

    collect({
      // tslint:disable-next-line:no-any
      eventData: rawEvent as any,
      config: this.config,
      isNewpage: false,
      withEnrich: true,
    });
  }

  public pageviewEbc(data?: IPageviewEbc) {
    const rawEvent = Object.assign(
      {
        category: 'page',
        action: 'newpage',
        label: 'open',
        event: 'newpage',
      },
      data,
    );

    collect({
      // tslint:disable-next-line:no-any
      eventData: rawEvent as any,
      config: this.config,
      isNewpage: true,
    });
  }

  /**
   * data - any - так как это динамически расширяемый объект на бекенде,
   * для GA, может иметь неограниченное количество полей, и расширяться
   * на бекенде в одностроннем порядке
   */
  // tslint:disable-next-line:no-any
  public sendGA(event: SoprEvent, data: any) {
    // tslint:disable-next-line:no-any
    const win: any = window;
    /**
     * dataFormatedForGA - "чистый" объект для отправки в GA
     */
    const { ea, ec, el, name, dl, ...dataFormatedForGA } = Object.assign(event.getDataLayerParams(), data);

    if (win.dataLayer) {
      // Уносим в отдельную таску, это нужно для оптимизации INP.
      // Сейчас push в GTM занимается половину времени выполнения таски. Отсюда возникла необходимость разбить таску.
      setTimeout(() => {
        win.dataLayer.push(dataFormatedForGA);
      }, 0);
    } else {
      win.dataLayer = [{ 'gtm.start': new Date().getTime(), event: 'gtm.js' }, dataFormatedForGA];
    }
  }

  public setConfig({ cianGK, profileSessionKey, soprApiUrl, mobileUserId }: IConfigParams) {
    this.config = new Config({
      cianGK,
      profileSessionKey,
      soprApiUrl,
      mobileUserId,
    });
  }

  public log() {
    this.logger.start();
  }

  private send(event: SoprEvent) {
    // tslint:disable-next-line:no-any
    const win: any = window;
    const soprApi = getSoprApi('old');

    const soprApiParams = {
      apiUrl: soprApi.apiUrl,
      path: soprApi.path,

      dataToSopr: event.getSoprParams(),

      contentType: 'application/x-www-form-urlencoded',
    };

    // SOPR
    SoprAPI(soprApiParams).catch(() => {
      console.warn('Error while sending metrics to SOPR');
    });

    // DATA LAYER
    if (win.dataLayer) {
      // Уносим в отдельную таску, это нужно для оптимизации INP.
      // Сейчас push в GTM занимается половину времени выполнения таски. Отсюда возникла необходимость разбить таску.
      setTimeout(() => {
        win.dataLayer.push(event.getDataLayerParams());
      }, 0);
    } else {
      win.dataLayer = [{ 'gtm.start': new Date().getTime(), event: 'gtm.js' }, event.getDataLayerParams()];
    }

    this.logger.addEvent(event);
  }
}
