import * as uuid from 'uuid';

import { ITeaserEvent, TTeaserUserEventData } from '../types/teaser';
import { Config } from '../models/config';
import { deleteEmptyFields } from './utils/deleteEmptyFields';
import { normalizeUserData } from './utils/normalizeUserData';
import { SoprAPI } from './utils/requestSopr';
import { getSoprApi } from './utils/getSoprApi';

export interface ISendTeaserParams {
  config: Config;
  data: TTeaserUserEventData;
}

export function sendTeaser({ config, data }: ISendTeaserParams) {
  const { product } = data;
  const user = data.user || config.user;
  const page = data.page || config.page;
  const sc = data.sc || config.sc;

  const event: ITeaserEvent = {
    user: user ? deleteEmptyFields(normalizeUserData(user)) : undefined,
    page: page ? deleteEmptyFields(page) : undefined,
    product,
    sc,
    cid: config.cianGK,
    dl: encodeURIComponent(document.location.href),
    // eslint-disable-next-line import/namespace
    join_id: uuid.v4(),
    referrer: document.referrer,
    rid: config.profileSessionKey,
    ua: encodeURIComponent(navigator.userAgent),
    viewport: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  };

  SoprAPI({
    data: JSON.stringify(event),
    ...getSoprApi('v2/teaser-web', true),
  }).catch(() => console.warn('Failed send sopr/v2/teaser event'));
}
