/**
 * Функция получения номера беты
 */
export function getStageId(host: string): string {
  const betaName = host.split('.').find(domainPart => domainPart.indexOf('beta') >= 0);

  const betaId = betaName ? betaName.replace(/\D+/gi, '') : 1;

  return String(betaId);
}
