import { getCookie } from '../../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getUpdatedPageForTestEvent(page: any): object {
  const isTestEvent = getCookie('QA-TestMode');

  if (isTestEvent) {
    if (page) {
      const { extra } = page;

      return {
        ...page,
        extra: {
          ...extra,
          isTest: 1,
        },
      };
    }

    return {
      extra: {
        isTest: 1,
      },
    };
  }

  return page;
}
