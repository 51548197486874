export interface ISoprApiParams {
  apiUrl: string;
  data: string;
  path: string;
}

/**
 * Функция для отправки данных в sopr
 */
export async function SoprAPI(params: ISoprApiParams): Promise<void> {
  const { apiUrl, data, path } = params;

  if (window.fetch) {
    const response = await window.fetch(`${apiUrl}${path}/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: data,
      keepalive: true,
      credentials: 'include',
    });

    if (response.status === 200 || response.status === 204) {
      return;
    }

    throw new Error();
  }

  const xhr = new XMLHttpRequest();

  return new Promise<void>((resolve, reject) => {
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200 || xhr.status === 204) {
          resolve();
        } else {
          reject();
        }
      }
    };

    xhr.withCredentials = true;
    xhr.open('POST', `${apiUrl}${path}/`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(data);
  });
}
