import { TSoprAPI } from './types';
import { calculateEnvironmentByHost } from './tracking/utils/calculateEnvironmentByHost';

export interface ISoprApiConfig {
  apiUrl: string;
  path: string;
}

export function getCookie(name: string) {
  const pairs = document.cookie.split(';');
  let found = '';

  pairs.forEach(item => {
    if (item.split('=')[0].trim() === name) {
      found = item;
    }
  });

  if (found) {
    return found.split('=')[1];
  }

  return null;
}

export function isObjContainsKey<O extends object, N, E>(obj: O | N | E, key: string): obj is O {
  return key in (obj as O);
}

export function getSoprPath(soprType: TSoprAPI): string {
  switch (soprType) {
    case 'ebc':
      return 'ebec';
    case 'old':
      return 'collect';
    case 'new':
      return 'site';
    default:
      return 'collect';
  }
}

export function getEbcApi() {
  const env = calculateEnvironmentByHost(window.location.host);
  let apiUrl;

  switch (env) {
    case 'development':
      apiUrl = 'http://master.ebc-analytics.dev3.cian.ru/';
      break;

    case 'beta':
      apiUrl = `https://beta${getStageId(window.location.host)}.ebc-analytics.stage.cian.ru/`;
      break;

    default:
      apiUrl = 'https://api.cian.ru/ebc-analytics/';
  }

  return {
    apiUrl,
    path: 'ebec',
  };
}

export function getSoprApi(soprType: TSoprAPI): ISoprApiConfig {
  const env = calculateEnvironmentByHost(window.location.host);

  const soprUrlConfig = {
    apiUrl: 'https://sopr-api.cian.ru/',
    path: getSoprPath(soprType),
  };

  if (env === 'development') {
    return Object.assign(soprUrlConfig, {
      apiUrl: 'https://sopr-api.dev.cian.ru/',
    });
  }

  if (env === 'beta') {
    const betaId = getStageId(window.location.host);

    return Object.assign(soprUrlConfig, {
      apiUrl: `https://beta${betaId}.sopr.stage.cian.ru/`,
    });
  }

  return soprUrlConfig;
}

export function getStageId(host: string): string {
  const betaName = host.split('.').find(domainPart => domainPart.indexOf('beta') >= 0);

  const betaId = betaName ? betaName.replace(/\D+/gi, '') : 1;

  return String(betaId);
}
