/**
 * Функция отправки данных в GA
 */
// tslint:disable-next-line:no-any
export function sendGA(data: any) {
  // tslint:disable-next-line:no-any
  const win: any = window;

  if (win.dataLayer) {
    // Уносим в отдельную таску, это нужно для оптимизации INP.
    // Сейчас push в GTM занимается половину времени выполнения таски. Отсюда возникла необходимость разбить таску.
    setTimeout(() => {
      win.dataLayer.push(data);
    }, 0);
  } else {
    win.dataLayer = [{ 'gtm.start': new Date().getTime(), event: 'gtm.js' }, data];
  }
}
