import { TObjectType } from '../types/objectTypes';
import { TOfferType } from '../types/offerTypes';

const typesForOffer = [
  'flat_old',
  'flat_new',
  'flat_new_dev',
  'flat_old,flat_new',
  'commercial',
  'suburban',
  'suburban_dev',
];

/**
 * Преобразуем objectType для старых событий в offerType для новых событий
 */
export function getOfferType(entityType: TObjectType | TOfferType) {
  if (typesForOffer.includes(entityType)) {
    return 'offer';
  }

  return entityType;
}
