import { Config } from '../../models/config';
import { deleteEmptyFields } from './deleteEmptyFields';

// tslint:disable-next-line:no-any - для совместимости со старыми событиями (page может придти любой)
export function getPage(config: Config, page: any, isNewPage: boolean) {
  if (isNewPage) {
    return deleteEmptyFields({ ...page });
  }

  return deleteEmptyFields({ ...config?.page, ...page });
}
