import { Config } from '../../models/config';
import { deleteEmptyFields } from './deleteEmptyFields';
export function getJsonQuery(config: Config, sc: object | undefined, isNewPage: boolean) {
  let jsonQuery = {};

  if (isNewPage) {
    jsonQuery = deleteEmptyFields({ ...sc });
  }

  if (!isNewPage && sc) {
    jsonQuery = deleteEmptyFields({ ...sc });
  }

  if (!isNewPage && !sc) {
    jsonQuery = deleteEmptyFields({ ...config?.sc });
  }

  if (!Object.keys(jsonQuery).length) {
    return null;
  }

  return jsonQuery;
}
