/**
 * *Приведение типа к any* - потому что с фронта в объекте события может придти что угодно (старые события, новые,
 * новые в новом формате, старые в новом формате и т.д.), описать под них всех интерфейсы не реально. Плюс данные
 * обогащаются с бекенда, и там список полей еще более динамический.
 */

import { Config } from '../models/config';
import { TObjectType } from '../types';
import { IProducts } from './types/products';
import { IFinityObject, IRootObject } from './types/rootObject';
import { filterPage } from './utils/filterPage';
import { getEbcApi } from './utils/getEbcApi';
import { getGAObject } from './utils/getGAObject';
import { getOfferType } from './utils/getOfferType';
import { getPage } from './utils/getPage';
import { getProducts } from './utils/getProducts';
import { getRightSoprKey } from './utils/getRightSoprKey';
import { getSoprApi } from './utils/getSoprApi';
import { getSoprExtendedData } from './utils/getSoprExtendedData';
import { getUpdatedPageForTestEvent } from './utils/getUpdatedPageForTestEvent';
import { getUser } from './utils/getUser';
import { EbcAPI } from './utils/requestEbc';
import { SoprAPI } from './utils/requestSopr';
import { sendGA } from './utils/sendGA';
import { getJsonQuery } from './utils/getJsonQuery';

const TEMPLATE_DATA_FOR_PAGEVIEW = {
  category: 'page',
  action: 'newpage',
  label: 'open',
  event: 'newpage',
  value: '',
};

export interface IInputData {
  eventData?: IRootObject;
  config: Config;
  isNewpage: boolean;
  withEnrich?: boolean;
}

export const collect = async (inputData: IInputData) => {
  try {
    const { eventData: eventDataRaw, config, isNewpage, withEnrich } = inputData;
    const needEnrich = isNewpage || withEnrich;

    if (eventDataRaw) {
      /* Обновляем конфиг для событий /ebec - они берут данные user и page из pageview */
      if (isNewpage) {
        config.update({
          /* eslint-disable @typescript-eslint/no-explicit-any */
          user: eventDataRaw.user as any,
          partner: (eventDataRaw as any).partner,
          page: eventDataRaw.page as any,
          products: eventDataRaw.products as any,
          sc: (eventDataRaw.sc as any) || (eventDataRaw.searchConditions as any),
          /* eslint-enable @typescript-eslint/no-explicit-any */
        });
      }

      const agregateData = {
        ...eventDataRaw,
        ...(isNewpage ? TEMPLATE_DATA_FOR_PAGEVIEW : {}),
      };

      /* Переименовываем поля для GA и SOPR */
      const rightData = Object.keys(agregateData).reduce((acc, key: keyof typeof eventDataRaw) => {
        acc[getRightSoprKey(key)] = agregateData[key];

        return acc;
        // tslint:disable-next-line:no-any
      }, {} as any);

      /* Дополняем объект служебными данными (реферрер, user-agent и тд) */
      const eventData = {
        ...rightData,
        ...getSoprExtendedData({
          eventType: isNewpage ? 'pageview' : 'event',
          cid: config.cianGK,
          rid: config.profileSessionKey,
        }),
      };

      /**
       * Достаем только нужные поля для нового формата аналитики.
       * Делаем так, потому что есть куча мест в разных микросервисах,
       * которые отправляют данных больше, либо в другом формате
       */
      const { t, v, cid, dl, rid, ua, ec, ea, el, sc, event, referrer, join_id, modelVersion, user, page } =
        eventData as IFinityObject;
      // tslint:disable-next-line: no-any
      const products: IProducts[] | void = getProducts(config, eventData) as any;
      const jsonQuery = getJsonQuery(config, sc, isNewpage);

      const finiteObject = {
        t,
        v,
        cid,
        dl,
        rid,
        ua,
        ec,
        ea,
        el,
        event,
        referrer,
        join_id,
        modelVersion,
        mobileUserId: config.mobileUserId,
        user: getUser(config, user, isNewpage),
        page: filterPage(getPage(config, page, isNewpage), needEnrich),
        products:
          products &&
          products.map(product => {
            let entityType = product.offerType as TObjectType;

            if (!entityType) {
              // Для совместимости со старыми событиями
              // tslint:disable-next-line:no-any
              entityType = (product as any).objectType || (page && ((page as any).objectType as TObjectType));
            }

            return {
              id: product.id,
              offerType: entityType && getOfferType(entityType),
              position: product.position,
              extra: product.extra,
            };
          }),
        ...(jsonQuery && { sc: jsonQuery }),
      };

      /**
       * Апдейтим поле page:
       *  если событие вызывается через автотест, в поле $.page.extra добавляем параметр isTest=1
       *  (нужно чтобы не накручивать статистику по метрикам через автотесты)
       */
      finiteObject.page = getUpdatedPageForTestEvent(finiteObject.page);

      SoprAPI({
        data: JSON.stringify({
          ...finiteObject,
          // пост фильтрация page, чтобы удалить поля нужные только для обогащения
          page: filterPage(finiteObject.page, false),
        }),
        ...getSoprApi('newbec'),
      }).catch(e => console.warn('Error while sending metrics to SOPR'));

      /* Обогащение для GA */
      if (needEnrich) {
        EbcAPI({
          data: JSON.stringify(finiteObject),
          ...getEbcApi('event-enrichment'),
        })
          .then(extendedData => sendGA(getGAObject(extendedData)))
          .catch(() => sendGA(getGAObject(finiteObject)));

        return;
      }

      sendGA(getGAObject(finiteObject));
    }
  } catch (e) {
    SoprAPI({
      data: JSON.stringify(inputData.eventData),
      ...getSoprApi('mobile-dev'),
    }).catch(() => console.warn('Error while sending metrics to SOPR'));
  }
};
