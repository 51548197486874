/**
 * Функция для замены ключей в объекте события, на ключи
 * для требуемого формата GA
 */
export function replaceKeysForGA(key: string) {
  const dict: { [key: string]: string } = {
    name: 'event',
    ec: 'eventCategory',
    el: 'eventLabel',
    ea: 'eventAction',
  };

  return dict[key] || key;
}
