export interface IEbcParams {
  apiUrl: string;
  path: string;
  data: string;
}

/**
 * Функция отправки данных в микросервис EBC
 */
// tslint:disable-next-line:no-any
export function EbcAPI(params: IEbcParams): Promise<any> {
  const xhr = new XMLHttpRequest();
  const { apiUrl, data, path } = params;

  return new Promise((resolve, reject) => {
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200 || xhr.status === 204) {
          if (xhr.status === 204) {
            resolve({});
          }

          try {
            resolve(JSON.parse(xhr.responseText));
          } catch (e) {
            reject();
          }
        } else {
          reject();
        }
      }
    };

    xhr.withCredentials = true;
    xhr.open('POST', `${apiUrl}${path}/`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(data);
  });
}
