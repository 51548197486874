import { IUserNewSopr } from '../../types';
import { IUserEbc } from '../../types/ebc';
import { IUser } from '../types/user';

/**
 * Функция нормализации данных о пользователе
 */
export function normalizeUserData(user: IUser | IUserNewSopr | IUserEbc) {
  return {
    // Для совместимости со старыми событиями
    // tslint:disable-next-line:no-any
    userId: 'userID' in user ? (user as any).userID : user.userId,
    extra: user.extra,
  };
}
