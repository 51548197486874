import { getStageId } from './getStageId';
import { calculateDomainByHost } from './calculateDomainByHost';
import { calculateEnvironmentByHost } from './calculateEnvironmentByHost';

export interface ICalcSoprApiParams {
  env: string;
  host: string;
  path: string;
  isTeaser?: boolean;
}

function getSoprApiUrl(env: string) {
  switch (env) {
    case 'development':
      return 'https://sopr-api.dev.cian.ru/';

    case 'beta':
      return `https://beta${getStageId(window.location.host)}.sopr.stage.cian.ru/`;

    default:
      return 'https://sopr-api.cian.ru/';
  }
}

function getSoprCreApiUrl(env: string) {
  switch (env) {
    case 'development':
      return 'http://master.sopr-api.dev3.cre.space/';

    case 'beta':
      return `https://beta${getStageId(window.location.host)}.sopr-api.stage.cre.space/`;

    default:
      return 'https://sopr-api.cre.space/';
  }
}

function getSoprTeaserApiUrl(env: string) {
  switch (env) {
    case 'development':
      return 'http://master.sopr-experiments-api.dev3.cian.ru/';

    case 'beta':
      return `https://api.beta${getStageId(window.location.host)}.stage.cian.ru/sopr-experiments/`;

    default:
      return 'https://api.cian.ru/sopr-experiments/';
  }
}

/**
 * Функция получения объекта { apiUrl + path } вычисляется по env,
 * для разных окружений SOPR
 */
export function calcSoprApi({ env, host, path, isTeaser }: ICalcSoprApiParams) {
  if (isTeaser) {
    return {
      apiUrl: getSoprTeaserApiUrl(env),
      path,
    };
  }

  if (host === 'cian.ru') {
    return {
      apiUrl: getSoprApiUrl(env),
      path,
    };
  }

  if (host === 'cre.space') {
    return {
      apiUrl: getSoprCreApiUrl(env),
      path,
    };
  }

  return {
    apiUrl: 'https://sopr-api.cian.ru/',
    path,
  };
}

/**
 * Функция получения объекта { host + path },
 * для разных окружений SOPR
 */
export function getSoprApi(path: string, isTeaser?: boolean) {
  const env = calculateEnvironmentByHost(window.location.host);
  const host = calculateDomainByHost(window.location.host);

  return calcSoprApi({ env, host, path, isTeaser });
}
