import {
  TEventType,
  IUser,
  IUserNewSopr,
  IPage,
  IPageNewSopr,
  IPartner,
  IPartnerNewSopr,
  ISOPRDataCollection,
  IUtmNewSopr,
  IProducts,
} from './../types';
import { IUtm } from './../types/utm';
import { isObjContainsKey } from './../utils';
import { IUserEbc, IPageEbc, IProductsEbc } from '../types/ebc';

function getUid(user: IUser | IUserNewSopr | IUserEbc): string | null {
  const uid = isObjContainsKey<IUser, IUserNewSopr, IUserEbc>(user, 'userID') ? user.userID : user.userId;

  return uid ? String(uid) : null;
}

export interface IConfigParams {
  cianGK: string;
  profileSessionKey: string;
  soprApiUrl: string;
  mobileUserId?: string;
}

export class Config {
  public user: IUser | IUserNewSopr | IUserEbc;
  public page: IPage | IPageNewSopr | IPageEbc;
  public partner: IPartner | IPartnerNewSopr;
  public products: IProducts[] | IProductsEbc[];
  public sc: object;

  public apiUrl: string;
  public profileSessionKey: string;
  public cianGK: string;
  public mobileUserId?: string;

  public utm: IUtm;

  public constructor(params: IConfigParams) {
    const { soprApiUrl, profileSessionKey, cianGK, mobileUserId } = params;

    this.apiUrl = soprApiUrl;
    this.profileSessionKey = profileSessionKey;
    this.cianGK = cianGK;
    this.mobileUserId = mobileUserId;
  }

  public setUser(data: IUser | IUserNewSopr | IUserEbc) {
    this.user = data;
  }

  public setPage(data: IPage | IPageNewSopr | IPageEbc) {
    const siteType = data.siteType === 'phone' ? 'mobile' : data.siteType;

    this.page = {
      ...data,
      siteType,
    };
  }

  public setPartner(data: IPartner | IPartnerNewSopr) {
    this.partner = data;
  }

  public setProducts(data: IProducts[] | IProductsEbc[]) {
    this.products = data;
  }

  public setSc(data: object) {
    this.sc = data;
  }

  public update(params: {
    user?: IUser | IUserNewSopr | IUserEbc;
    partner?: IPartner | IPartnerNewSopr;
    page?: IPage | IPageNewSopr | IPageEbc;
    products?: IProducts[] | IProductsEbc[];
    sc?: object;
  }) {
    const { user, partner, page, products, sc } = params;

    this.user = user || this.user;
    this.partner = partner || this.partner;
    this.page = page || this.page;
    this.products = products || this.products;
    this.sc = sc || this.sc;
  }

  /**
   * withUtm - по умолчанию должен быть true, чтобы не сломать старые события в /collect и /site,
   * в новую ручку /ebec принудительно выставляется в false
   */
  public getSoprData(type: TEventType, withUtm: boolean = true): ISOPRDataCollection {
    const utmOrigin: IUtm = this.getUtmData();

    const sopr: ISOPRDataCollection = {
      t: type,
      v: '1',
      cid: this.cianGK || 'noid',
      dl: encodeURIComponent(document.location.href),
      rid: this.profileSessionKey,
      ua: encodeURIComponent(navigator.userAgent),
      mobileUserId: this.mobileUserId,
    };

    if (withUtm) {
      const utmForNewSopr: IUtmNewSopr = {};

      Object.keys(utmOrigin).forEach(utmKey => {
        const utmKeyName = utmKey.replace('utm_', '');

        Object.assign(utmForNewSopr, {
          [utmKeyName]: utmOrigin[utmKey],
        });
      });

      Object.assign(sopr, { utm: utmForNewSopr });
    }

    const uid = this.user && getUid(this.user);

    /**
     * Добавляем поле uid, только если получилось его получить,
     * может быть undefined или null, для не авторизованных пользователей.
     */
    if (uid) {
      Object.assign(sopr, {
        uid,
      });
    }

    if (this.page) {
      // По новой схеме АПИ vas в page не передаем
      const vas = isObjContainsKey<IPage, IPageNewSopr, IPageEbc>(this.page, 'offerVAS')
        ? this.page.offerVAS
        : undefined;

      // По схеме СОПР_ЕБЦ не передаем dealType
      const dealType = isObjContainsKey<IPage, IPageNewSopr, IPageEbc>(this.page, 'dealType')
        ? this.page.dealType
        : undefined;

      // По схеме СОПР_ЕБЦ не передаем dealType
      const objectType = isObjContainsKey<IPage, IPageNewSopr, IPageEbc>(this.page, 'objectType')
        ? this.page.objectType
        : undefined;

      sopr.pt = this.page.pageType.toLowerCase();
      sopr.ds = this.page.siteType;
      sopr.deal_type = dealType;
      sopr.object_type = objectType;
      sopr.vas = vas;
      sopr.oid = this.page.offerID;
    }

    this.collectUtm(sopr);

    for (const key in sopr) {
      if (!sopr[key]) {
        delete sopr[key];
      }
    }

    return sopr;
  }

  private collectUtm(sopr: ISOPRDataCollection) {
    const data: IUtm = this.getUtmData();

    if (data.utm_campaign) {
      sopr.ci = data.utm_campaign;
      sopr.cs = data.utm_source;
      sopr.cm = data.utm_medium;
      sopr.ck = data.utm_term;
      sopr.cc = data.utm_content;
    }
  }

  private getUtmData = (): IUtm => {
    const queryStrings: string[] = location.search.replace('?', '').split('&');
    const utmData: IUtm = {
      utm_campaign: undefined,
      utm_content: undefined,
      utm_term: undefined,
      utm_medium: undefined,
      utm_source: undefined,
    };

    queryStrings.forEach(value => {
      const params = value.split('=');
      if (params[0] in utmData) {
        utmData[params[0]] = params[1];
      }
    });

    return utmData;
  };
}
