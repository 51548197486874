export const LISTING = ['Listing', 'ListingKP', 'ListingJK', 'ListingBC', 'ListingTC', 'ListingWC', 'ListingFavorites'];

export const CARD = [
  'Card',
  'CardKP',
  'CardBC',
  'CardTC',
  'CardWC',
  'CardJK',
  'CardAgent',
  'CardFinance',
  'CardDemand',
];

export const LISTING_FINANCE = 'ListingFinance';
export const LISTING_DEMAND = 'ListingDemand';

// tslint:disable-next-line:no-any
export function filterPage(page: any, withEnrich: boolean = false) {
  const { pageType } = page;

  if (pageType) {
    if (LISTING.includes(pageType)) {
      const {
        listingType,
        pageNumber,
        queryString,
        offersQty,
        siteType,
        extra,
        mlRankingGuid,
        mlRankingModelVersion,
        searchGuid,
      } = page;

      return {
        listingType,
        pageNumber,
        queryString,
        offersQty,
        siteType,
        extra,
        mlRankingGuid,
        mlRankingModelVersion,
        searchGuid,
        pageType,
      };
    }

    if (CARD.includes(pageType)) {
      const { siteType, extra, offerID, offerType } = page;

      return withEnrich ? { pageType, siteType, extra, offerID, offerType } : { pageType, siteType, extra };
    }

    if (pageType === LISTING_FINANCE) {
      const { listingType, pageNumber, queryString, siteType, extra } = page;

      return { listingType, pageNumber, queryString, siteType, extra, pageType };
    }

    if (pageType === LISTING_DEMAND) {
      const { siteType, pageNumber, extra } = page;

      return { siteType, pageNumber, extra, pageType };
    }
  }

  return {
    ...page,
    siteType: page.siteType === 'phone' ? 'mobile' : page.siteType,
  };
}
