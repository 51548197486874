import { SoprEvent } from './../models/event';

export class Logger {
  private isActive = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private logStack: any[] = [];

  public addEvent(event: SoprEvent) {
    this.logStack.push(event);
    if (this.isActive) {
      // eslint-disable-next-line no-console
      console.log('CIAN analytics send', event);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public addMessage(message: string, data: any) {
    console.warn(message, data);
  }

  public start() {
    this.isActive = true;

    this.logStack.forEach(event => {
      // eslint-disable-next-line no-console
      console.log(`CA "${event.sopr.t}": `, event);
    });
  }
}
