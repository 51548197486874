import { getStageId } from '../../utils';
import { calculateEnvironmentByHost } from './calculateEnvironmentByHost';

/**
 * Функция получения объекта { host + path },
 * для разных окружений EBC
 */
export function getEbcApi(path: string) {
  const env = calculateEnvironmentByHost(window.location.host);
  let apiUrl;

  switch (env) {
    case 'development':
      apiUrl = 'http://master.ebc-analytics.dev3.cian.ru/';
      break;

    case 'beta':
      apiUrl = `https://beta${getStageId(window.location.host)}.ebc-analytics.stage.cian.ru/`;
      break;

    default:
      apiUrl = 'https://api.cian.ru/ebc-analytics/';
  }

  return {
    apiUrl,
    path,
  };
}
