import { Config } from '../../models/config';
import { IEventProperties } from '../../types';
import { IEventEbc } from '../../types/ebc';

export function getProducts(config: Config, data: IEventProperties | IEventEbc) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((data.useLastProducts as any) === true) {
    return config.products;
  }

  return data.products;
}
