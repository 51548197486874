import * as uuid from 'uuid';
import { TEventType } from '../types/eventType';

export interface IExtendedSOPRData {
  t: string;
  v: string;
  cid: string;
  dl: string;
  rid: string;
  ua: string;
  referrer: string;
  join_id: string;
}

export interface IInputData {
  eventType: TEventType;
  cid: string | undefined;
  rid: string;
}

/**
 * Функция получения служебных полей для события аналитики
 */
export function getSoprExtendedData(params: IInputData): IExtendedSOPRData {
  const { eventType, cid, rid } = params;

  return {
    t: eventType,
    v: '1',
    cid: cid || 'noid',
    dl: encodeURIComponent(document.location.href),
    rid,
    ua: encodeURIComponent(navigator.userAgent),
    referrer: document.referrer,
    // eslint-disable-next-line import/namespace
    join_id: uuid.v4(),
  };
}
