export function deleteEmptyFields(data: object) {
  const dataWithOutUndefined = JSON.parse(JSON.stringify(data));

  Object.keys(dataWithOutUndefined).forEach((key: keyof typeof dataWithOutUndefined) => {
    if (dataWithOutUndefined[key] === null) {
      delete dataWithOutUndefined[key];
    }
  });

  return dataWithOutUndefined;
}
