import { TEventType, ICaInputData } from './types';
import { getCookie } from './utils';
import { Service } from './api/service';

export class CianAnalytics {
  private service: Service;
  public hasPageview = false;
  private queue: [TEventType, ICaInputData][] = [];

  public constructor() {
    this.initializeConfig();

    const predefinedCA = window.ca;
    const { q } = predefinedCA;

    for (let i = 0; i < q.length; i++) {
      const queueItem = q[i];
      this.ca(queueItem[0], queueItem[1]);
    }

    window.ca = this.ca as typeof window.ca;
  }

  private initializeConfig() {
    const currentScript = document.currentScript || document.querySelector('[data-analytics-api-url]');

    if (!currentScript) {
      throw new Error();
    }

    this.service = new Service();
    this.service.setConfig({
      soprApiUrl: currentScript.getAttribute('data-analytics-api-url') || '',
      profileSessionKey: currentScript.getAttribute('data-profile-session-key') || '',
      cianGK: getCookie('_CIAN_GK') || '',
      mobileUserId: getCookie('mobile_user_id') || undefined,
    });
  }

  private processQueue() {
    for (let i = 0; i < this.queue.length; i++) {
      const item = this.queue[i];

      this.send(item[0], item[1]);
    }

    this.queue = [];
  }

  private enqueue(eventType: TEventType, data: ICaInputData) {
    this.queue.push([eventType, data]);
  }

  // Этот метод заменяет глобальный «ca»
  private ca = (eventType: TEventType, data: ICaInputData): void => {
    const { hasPageview } = this;

    if (!hasPageview && eventType === 'eventSite') {
      this.enqueue(eventType, data);

      return;
    }

    this.send(eventType, data);

    if (['pageview', 'pageviewSite'].includes(eventType)) {
      this.hasPageview = true;

      if (this.queue.length) {
        this.processQueue();
      }
    }
  };

  private send(eventType: TEventType, data: ICaInputData): void {
    if (!this.service.config.apiUrl) {
      return;
    }

    if (!this.service[eventType]) {
      this.service.logger.addMessage(`Error: event type - "${eventType}" not found`, data);

      return;
    }

    (this.service as any)[eventType](data);
  }
}
