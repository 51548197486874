export type HostNamePattern = [string, RegExp];

const hostPatterns: HostNamePattern[] = [
  ['cian.ru', /cian\.ru$/i],
  ['cian.ru', /^localhost(:\d+)?$/i],
  ['cian.ru', /^127\.0\.0\.1(:\d+)?$/i],
  ['cre.space', /cre\.space$/i],
];

export function calculateDomainByHost(host: string): string {
  for (const hostPattern of hostPatterns) {
    if (hostPattern[1].test(host)) {
      return hostPattern[0];
    }
  }

  return 'unknown';
}
