import { replaceKeysForGA } from './replaceKeysForGA';

/**
 * Функция возвращающая объект требуемый GA
 */
// tslint:disable-next-line:no-any
export function getGAObject(data: any) {
  return Object.keys(data).reduce((acc, key: keyof typeof data) => {
    if (data[key]) {
      acc[replaceKeysForGA(key as string)] = data[key];
    }

    return acc;
    // tslint:disable-next-line:no-any
  }, {} as any);
}
