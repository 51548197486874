type HostPattern = [string, RegExp];

const hostPatterns: HostPattern[] = [
  ['beta', /stage(\.cian\.ru|\.cre\.space)$/i],
  ['development', /dev[2,3]?(\.cian\.ru|\.cre\.space)$/i],
  ['local', /local(\.map)?\.cian\.ru$/i],
  ['local', /^localhost(:\d+)?$/i],
  ['local', /^127\.0\.0\.1(:\d+)?$/i],
  ['production', /(cian\.ru|cre\.space)$/i],
];

export function calculateEnvironmentByHost(host: string): string {
  for (const hostPattern of hostPatterns) {
    if (hostPattern[1].test(host)) {
      return hostPattern[0];
    }
  }

  return 'unknown';
}
