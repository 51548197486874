import { Config } from '../../models/config';
import { deleteEmptyFields } from './deleteEmptyFields';
import { normalizeUserData } from './normalizeUserData';

// tslint:disable-next-line:no-any - для совместимости со старыми событиями (user может придти любой)
export function getUser(config: Config, user: any, isNewPage: boolean) {
  if (isNewPage) {
    const preparedUser = deleteEmptyFields(normalizeUserData({ ...user }));

    return Object.keys(preparedUser).length ? preparedUser : {};
  }

  const preparedUser = deleteEmptyFields(normalizeUserData({ ...config?.user, ...user }));

  return Object.keys(preparedUser).length ? preparedUser : {};
}
